import { Controller } from "stimulus"

import { debounce } from "../rails/debounce"

export default class extends Controller {
  static targets = ["identityCard", "name", "birthdate", "phone", "autoCompleteContainer", "saveBtn"]

  autoCompletePlayer() {
    if (!this.identityCardTarget.value) {
      return
    }

    this.fetchPlayer(this.identityCardTarget.value, this.showAutoComplete.bind(this))
  }

  enableSaveBtn() {
    console.log(this.saveBtnTarget)
    this.saveBtnTarget.parentNode.classList.remove("hidden")
  }

  fetchPlayer(identityCard, callback) {
    debounce($.get("/jogadores/autocomplete", { prefix: identityCard },callback), 150)
  }

  showAutoComplete(data) {
    if (data.players && data.players.length > 0) {
      let playerOptions = ""
      data.players.forEach(option => {
        playerOptions += `
          <div data-name="${option.name}"
               data-identity-card="${option.identity_card}"
               data-phone="${option.phone}"
               data-birthdate=${option.birthdate}
               data-action="click->player#updatePlayer"
               class="autocomplete-option"><span>${option.identity_card}</span> <span>${option.name}</span></div>
        `
        let html = `<div>${playerOptions}</div>`
        this.autoCompleteContainerTarget.innerHTML = html
      })
    } else {
      this.autoCompleteContainerTarget.innerHTML = ""
    }
  }

  updatePlayer(e) {
    let elem = e.target.closest(".autocomplete-option")
    this.nameTarget.value = elem.dataset.name
    let dateParts = elem.dataset.birthdate.split("-")
    this.birthdateTargets[0].value = parseInt(dateParts[2])
    this.birthdateTargets[1].value = parseInt(dateParts[1])
    this.birthdateTargets[2].value = parseInt(dateParts[0])

    this.phoneTarget.value = elem.dataset.phone
    this.identityCardTarget.value = elem.dataset.identityCard
    this.autoCompleteContainerTarget.innerHTML = ""
  }

  remove(e) {
    e.target.closest(".player-form").remove()
  }

  showCantDeleteAlert(e) {
    e.preventDefault()
    window.alert('Operação não autorizada! O atleta já tem uma presença marcada ou faz parte de um quadro competitivo.')
  }
}
