import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["season", "event"]

  connect() {
  }

  seasonChanged() {
    let season = this.seasonTarget.value
    let node = this.eventTarget
    if(season && season !== "") {
      $.get(`/administrador/eventos?season_id=${season}`, function (data) {
        if (!data || data.length == 0) {
          node.innerHTML = nil
        } else {
          let html = ""
          data.forEach((data) => {
            let [description, value] = data
            html += `<option value="${value}">${description}</option>`
          })
          node.innerHTML = html
        }
      })
    }
  }
}