import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['kind', 'turn']

  connect() {
    this.toggleTurns()
  }

  toggleTurns() {
    if (this.kindTarget.value === 'experimental') {
      this.showTurns()
    } else {
      this.hideTurns()
    }
  }

  showTurns() {
    console.log('show turns')
    this.turnTargets.forEach(element => {
      if (element.classList.contains('hidden')) {
        element.classList.remove('hidden')
      }
    })
  }

  hideTurns() {
    console.log('hide turns')
    this.turnTargets.forEach(element => {
      if (!element.classList.contains('hidden')) {
        element.classList.add('hidden')
        element.querySelectorAll('input').forEach(elem => elem.value = null)
      }
    })
  }
}
