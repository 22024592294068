import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["form", "viewable"]

  connect() {
  }

  edit() {
    this.viewableTarget.classList.add("hidden")
    this.formTarget.classList.remove("hidden")
  }

  cancel() {
    this.formTarget.classList.add("hidden")
    this.viewableTarget.classList.remove("hidden")
  }
}