import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["heading", "saveTeamButton", "addPlayerButton", "addPlayerFakeButton"]

  connect() {
    this.assignPlayerNumbers({}, true)
  }

  assignPlayerNumbers(event, skipAutoScroll) {
    let i = 1
    this.headingTargets.forEach(el => {
      el.innerHTML = `Atleta ${i}`
      i += 1
    })

    this.setButtonsStatus()

    if (!skipAutoScroll) {
      this.scrollToLast()
    }
  }

  scrollToLast() {
    let elem = this.headingTargets[this.headingTargets.length - 1]
    let elems = $('html, body')
    if(elems.animate) {
      elems.animate({
        scrollTop: $(elem).offset().top
      }, 1000);
    }
  }

  removeFile(e) {
    $(e.target.parentElement.parentElement).find("input[type='file']").val(null);
    $(e.target.parentElement.parentElement).find("input[type='hidden']").remove();
    $(e.target.parentElement.parentElement).find(".remove-file").addClass("hidden")
    $(e.target.parentElement.parentElement).find("input[type='file']").trigger("change");
  }

  setButtonsStatus() {
    // this.addPlayerButtonTarget.classList.remove("hidden")
    // this.addPlayerFakeButtonTarget.classList.add("hidden")

    // if (this.headingTargets.length <= 0) {
    //   this.saveTeamButtonTarget.disabled = true
    // } else {
    //   this.saveTeamButtonTarget.disabled = false
    // }
  }
}
