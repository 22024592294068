import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["penalties", "penaltiesInput"]

  connect() {
    this.togglePenalties()
  }

  togglePenalties() {
    if(this.penaltiesTarget.checked) {
      this.penaltiesInputTargets.forEach(input => {
        input.parentNode.style.display = "block"
      })
    } else {
      this.penaltiesInputTargets.forEach(input => {
        input.parentNode.style.display = "none"
        input.value = 0
      })
    }
  }
}