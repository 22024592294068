import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["toggle_button", "submenu"]

  connect() {
  }

  toggleMenu() {
    // this.submenuTarget.classList.toggle('hidden')
    $(this.submenuTarget).slideToggle("normal", function () {
      // Animation complete.
    });
  }

  hideMenu(event) {
    if (this.element.contains(event.target) == false && this.submenuTarget.style.display == "block") {
      $(this.submenuTarget).slideToggle("normal")
    }
  }
}