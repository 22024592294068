import 'core-js/stable'
import 'regenerator-runtime/runtime'
import "element-closest";
import "mutation-observer-inner-html-shim"
import "./ajax"
import "./custom_event"
import "./array"
import "./animations"
import "./cocoon"
import { Tabs } from "tailwindcss-stimulus-components"

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Flatpickr from "../controllers/flatpickr_controller";
import "../components/upload_input"
import toastr from "toastr"
import * as ActiveStorage from "activestorage"
import { Autocomplete } from 'stimulus-autocomplete'
ActiveStorage.start()

window.toastr = toastr

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))
application.register("flatpickr", Flatpickr);
application.register('autocomplete', Autocomplete)
application.register('tabs', Tabs)

// Transitions between pages
document.addEventListener("turbo:load", (e) => {
   $('#content').animateCss("fadeIn")
})
