import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    level: String,
    message: String
  }

  connect() {
    console.log(this.levelValue)

    const method = window.toastr[this.levelValue]
    if(method) {
      method(this.messageValue)
    }
  }
}