import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["groups", "strategy", "league", "bronze", "bronzeInput"]
  connect() {
    this.toggleExtraOptions()
  }

  toggleExtraOptions() {
    this.toggleGroupsSize()
    this.toggleLeagueRounds()
    this.toggleBronzeMatch()
  }

  toggleGroupsSize() {
    if (this.strategyTarget.value == "groups") {
      this.groupsTarget.style.display = "block"
    } else {
      this.groupsTarget.style.display = "none"
    }
  }

  toggleLeagueRounds() {
    if (this.strategyTarget.value == "league") {
      this.leagueTarget.style.display = "block"
    } else {
      this.leagueTarget.style.display = "none"
    }
  }

  toggleBronzeMatch() {
    if (this.strategyTarget.value == "groups" || this.strategyTarget.value == "playoff") {
      this.bronzeTarget.style.display = "block"
    } else {
      this.bronzeInputTarget.checked = false
      this.bronzeTarget.style.display = "none"
    }
  }
}