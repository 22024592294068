import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dateField", "authorizationForm", "covidCertificateForm"]

  connect() {
    this.toggleCovidCertificateForm()
  }

  age() {
    let day = this.dateFieldTargets[0].value
    let month = this.dateFieldTargets[1].value
    let year = this.dateFieldTargets[2].value

    let dob = new Date(year, month - 1, day)
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms);
    let result = Math.abs(age_dt.getUTCFullYear() - 1970);
    return result
  }

  toggleCovidCertificateForm() {
    if (this.age() < 12) {
      if (!this.covidCertificateFormTarget.classList.contains("hidden")) {
        this.covidCertificateFormTarget.classList.add("hidden")
      }
    } else {
      this.covidCertificateFormTarget.classList.remove("hidden")
    }
  }
}