$(document).on("click", ".upload > i,.upload span.name", function() {
  $(this).siblings("input[type='file']").trigger('click');
})

$(document).on('change', 'input[type="file"]', function () {
  var val = $(this).val();
  if (val) {
    let components = $(this).val().split("\\")
    $(this).siblings('span').text(components[components.length - 1]);
    $(this.parentElement.parentElement.parentElement).find(".remove-file").removeClass("hidden");
  } else {
    $(this).siblings('span').text("Upload da ficha de autorização preenchida");
  }
})