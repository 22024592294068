// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "team_name", "level", "discipline", "municipality" ]

  connect() {
  }

  handleDisciplineUpdate() {
    const disciplineId = this.disciplineTarget.value
    const parentNode = this.levelTarget.parentNode
    const node = this.levelTarget

    $.get(`/inscricoes/escaloes?discipline_id=${disciplineId}`, function( data ) {
      if (!data || data.length == 0) {
        node.innerHTML = nil
      } else {
        let html = ""
        data.forEach((data) => {
          let [description, value] = data
          html += `<option value="${value}">${description}</option>`
        })
        node.innerHTML = html
      }
    });
  }

  handleLevelUpdate() {
    const level = this.levelTarget
    if(level.value) {

      $.get(`/inscricoes/restricoes?level_id=${level.value}`, function( json ) {
        if (json && json.restrictions) {
          var html = `
            <div class = "input boolean required enrollment_form_accept_special_restrictions">
              <input name = "enrollment_form[accept_special_restrictions]"
                     type = "hidden"
                     value = "0" >
              <input class = "boolean required"
                     required = "required"
                     aria-required = "true"
                     type = "checkbox"
                     value = "1"
                     name = "enrollment_form[accept_special_restrictions]"
                     id = "enrollment_form_accept_special_restrictions" />
              <label class="boolean required" for="enrollment_form_accept_special_restrictions">
                <abbr title="obrigatório"> * </abbr> ${json.message}
              </label>
            </div>
            `
          $("#restrictions_container").html(html)
        } else {
          $("#restrictions_container").html(null)
        }
      })
    } else {
      $("#restrictions_container").html(null)
    }
  }
}
