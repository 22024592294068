import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["levelTitle", "level"]
  connect() {
    this.assignLevelTitles()
  }

  assignLevelTitles() {
    this.levelTitleTargets.forEach((el, index) => {
      el.innerHTML = `Escalão ${index + 1}`
    });
  }

  addLevel() {
    this.assignLevelTitles()
    this.scrollToLast()
  }

  scrollToLast() {
    let elem = this.levelTitleTargets[this.levelTitleTargets.length - 1]
    let elems = $('html, body')
    if (elems.animate) {
      elems.animate({
        scrollTop: $(elem).offset().top
      }, 1000);
    }
  }
}