$(document).on('cocoon:before-insert', '#player-fields', function(e, task) {
  task.fadeIn('slow')
});

$(document).on('cocoon:after-insert', '#player-fields', function (e, task) {
  const event = document.createEvent("CustomEvent")
  event.initCustomEvent("team-changed", true, true, null)
  document.dispatchEvent(event)
});

$(document).on('cocoon:before-remove', '#player-fields', function (e, task) {
  const event = document.createEvent("CustomEvent")
  event.initCustomEvent("team-changed", true, true, null)
  document.dispatchEvent(event)
});

$(document).on('cocoon:after-remove', function (e, task) {
  if(task.length > 0 && task[0].classList.contains("player-form")) {
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("team-changed", true, true, null)
    document.dispatchEvent(event)
  }
});
