import { Controller } from "stimulus"
import { debounce } from "../rails/debounce"

export default class extends Controller {
  initialize() {
    this.autoSubmit = debounce(this.autoSubmit.bind(this), 500)
  }

  clearInputErrors(event) {
    let form = event.target.closest("form")
    form.querySelector(".error_notification").remove()
    let container = event.target.closest(".field_with_errors")
    container.classList.remove("field_with_errors")
    container.querySelector("span.error").remove()
  }

  removeFile(e) {
    $(e.target.parentElement.parentElement).find("input[type='file']").val(null);
    $(e.target.parentElement.parentElement).find("input[type='hidden']").remove();
    $(e.target.parentElement.parentElement).find(".remove-file").addClass("hidden")
    $(e.target.parentElement.parentElement).find("input[type='file']").trigger("change");
  }

  autoSubmit(e) {
    let activeElement = document.activeElement
    const focusInputAfterSubmit = () => {
      e.target.closest("form").removeEventListener("turbo:submit-end", focusInputAfterSubmit)

      if (activeElement) {
        setTimeout(() => {
          if (activeElement) {
            activeElement.focus()
            let value = activeElement.value
            activeElement.value = ''
            activeElement.value = value
          }
        }, 100)
      }
    }

    e.target.closest("form").addEventListener("turbo:submit-end", focusInputAfterSubmit)

    e.target.closest("form").requestSubmit()
  }
}