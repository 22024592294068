$(document).on('cocoon:before-insert', '#levels', function (e, task) {
  task.fadeIn('slow')
});

$(document).on('cocoon:after-insert', '#levels', function (e, task) {
  const event = document.createEvent("CustomEvent")
  event.initCustomEvent("added-level", true, true, null)
  document.dispatchEvent(event)
});

$(document).on('cocoon:before-remove', function (e, task) {
  if (task.length > 0 && task[0].classList.contains("level")) {
    $(this).data('remove-timeout', 1000);
    task.fadeOut('slow');
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("removed-level", true, true, null)
    document.dispatchEvent(event)
  }
});

$(document).on('cocoon:after-remove', function (e, task) {
  if(task.length > 0 && task[0].classList.contains("level")) {
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("removed-level", true, true, null)
    document.dispatchEvent(event)
  }
});
