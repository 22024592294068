import {Controller } from "stimulus"
export default class extends Controller {
  static targets = ["close", "container"]

  connect() {
    document.querySelector("body").classList.add("overflow-hidden")
  }

  close() {
    document.querySelectorAll(".modal").forEach(item => item.remove())
    document.querySelector("body").classList.remove("overflow-hidden")
  }
}