import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["level", "discipline"]

  connect() {
    $(document).on("cocoon:after-insert", () => { this.setLevelOptions() })
  }

  setLevelOptions() {
    const disciplineId = this.disciplineTarget.value
    const nodes = this.levelTargets.filter(node => {
      return node.options.length == 1 && node.value == ""
    })

    $.get(`/administrador/disciplines/${disciplineId}/escaloes.json`, function (data) {
      if (!data || data.length == 0) {
        nodes.forEach(node => node.innerHTML = null)
      } else {
        let html = ""
        data.forEach((data) => {
          let [description, value] = data
          html += `<option value="${value}">${description}</option>`
        })
        nodes.forEach(node => node.innerHTML = html)
      }
    })
  }

  disciplineChanged() {
    const disciplineId = this.disciplineTarget.value
    const nodes = this.levelTargets

    if(disciplineId) {
      $.get(`/administrador/disciplines/${disciplineId}/escaloes.json`, function (data) {
        if (!data || data.length == 0) {
          nodes.forEach(node => node.innerHTML = null)
        } else {
          let html = ""
          data.forEach((data) => {
            let [description, value] = data
            html += `<option value="${value}">${description}</option>`
          })
          nodes.forEach(node => node.innerHTML = html)
        }
      })
    }
  }
}
