import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["header"]

  connect() {
    this.updateHeaders()
    $(document).on("cocoon:after-insert", () => { this.updateHeaders() })
  }

  updateHeaders() {
    let idx = 1
    this.headerTargets.forEach(header => header.innerHTML = `Prova ${idx++}`)
  }
}
